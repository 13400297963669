<template>
  <div>
    <div class="container">
      <div class="page-title">News and <span>Events</span></div>
    </div>
    <news-content :newsData="newsData" :attachments="attachments"></news-content>
  </div>
</template>
<script>
import NewsContent from '../components/NewsContent'
export default {
  name: "single-news",
  components:{
    NewsContent
  },
  data() {
    return {
      endpoint: process.env.VUE_APP_ENDPOINT,
      newsData: null,
      attachments: [],
    };
  },
  computed: {
    nid() {
      return this.$route.params.nid.split('-').slice('-1')[0];
    },
  },
  methods: {
    getAttachments() {
      if (
        this.newsData.relationships.field_shared_attachment &&
        this.newsData.relationships.field_shared_attachment.data
      ) {
        this.newsData.relationships.field_shared_attachment.data.forEach(
          (attachment) => {
            fetch(
              `${process.env.VUE_APP_ENDPOINT}/jsonapi/file/file/${attachment.id}`
            )
              .then((r) => r.json())
              .then((json) => {
                this.attachments.push(json);
              });
          }
        );
      }
    },

    fetchNews() {
      fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/news?filter[drupal_internal__nid]=${this.nid}`, {
        method: "GET",
        mode: "cors",
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.newsData = json.data[0];
          this.getAttachments();
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
    },
  },
  mounted() {
    this.fetchNews();
  },
};
</script>
<style lang="scss"  >
.page-title {
  font-size: 36px;
  margin-top: 120px;
  margin-bottom: 57px;
  font-family: Ubuntu;
  line-height: 1.8rem;
  font-weight: 300;
  span{
    color: #1DC07E;
  }
}

</style>